<template>
  <!-- 系统 - 平台默认配置 -->
  <div class="box-card">
    <le-card title="平台默认配置">
      <template slot="heard-right">
        <div class="right">
          <lbButton icon="xinzeng" @click="saveConfig" v-preventReClick>保存</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <el-form :model="queryForm" ref="queryForm" label-width="150px" class="addForm" @submit.native.prevent>
          <!-- <el-form-item label="默认登录标题:">
          <el-input style="width: 500px" placeholder="请输入平台名称" autocomplete="off" maxlength="15" :value="queryForm.login_title" @input="(e) => (queryForm.login_title = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="默认登录LOGO:" class="logo_upload_box">
          <el-image fit="contain" :src="queryForm.login_logo" :preview-src-list="[queryForm.login_logo]" v-if="queryForm.login_logo"></el-image>
          <el-upload class="logo-upload" action="''" :http-request="LoginUpload" :show-file-list="false" accept="image/gif,image/jpeg,image/jpg,image/png" :before-upload="beforeAvatarUpload2">
            <el-button size="small" type="warning">{{
              queryForm.login_logo.length > 0 ? "重新上传" : "上传"
            }}</el-button>
          </el-upload>
          <span class="logoinfo">说明:仅支持jpg,gif,jpeg,png格式的图片，大小&lt;=1MB</span>
        </el-form-item> -->
          <el-form-item label="默认平台名称:">
            <el-input style="width: 500px" placeholder="请输入平台名称" autocomplete="off" maxlength="30"
              :value="queryForm.platform_name" @input="(e) => (queryForm.platform_name = divideSymbol(e))"></el-input>
          </el-form-item>
          <el-form-item label="默认平台LOGO:" class="logo_upload_box">
            <el-image fit="contain" :src="queryForm.logo_url" :preview-src-list="[queryForm.logo_url]"
              v-if="queryForm.logo_url"></el-image>
            <div class="handelButton">
              <!-- 删除已上传logo -->
              <lbButton size="small" type="warning" class="deleteLogo" @click="deleteLogo"
                v-if="queryForm.logo_url">删除</lbButton>
              <!-- <img v-if="queryForm.logo_url !== ''" :src="queryForm.logo_url" class="imagelogo" /> -->
              <el-upload class="logo-upload" action="''" :http-request="upload" :show-file-list="false"
                accept="image/gif,image/jpeg,image/jpg,image/png" :before-upload="beforeAvatarUpload2">
                <lbButton size="small" :type="queryForm.logo_url.length > 0 ?'warning':'default'">{{
                  queryForm.logo_url.length > 0 ? "重新上传" : "上传"
                }}</lbButton>
              </el-upload>
              <span class="logoinfo el-icon-warning-outline">说明:仅支持jpg,gif,jpeg,png格式的图片，大小&lt;=1MB</span>
            </div>
          </el-form-item>
          <el-form-item label="登录页：">
            <ul class="theme_box">
              <li :class="[
                'img_box','hover_box',
                queryForm.login_page == item ? 'is_check' : ''
              ]" v-for="(item) in loginpageList" :key="item" @click="queryForm.login_page = item">
                <div class="preview_box">
                  <lbButton size="small" type="white" class="previewLoginPage" @click="previewLoginPage">预 览</lbButton>
                </div>
                <el-image fit="contain" :src="require(`@/assets/images/group/login${item}.png`)"></el-image>
                <i class="iconfont lebo-chenggong1" v-if="queryForm.login_page == item"></i>
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="主题：">
            <ul class="theme_box">
              <li :class="[
                'img_box',
                queryForm.theme == item ? 'is_check' : '',
              ]" v-for="item in themeList" :key="item" @click="queryForm.theme = item">
                <el-image fit="contain" :src="require(`@/assets/images/group/theme${item}.png`)"></el-image>
                <i class="iconfont lebo-chenggong1" v-if="queryForm.theme == item"></i>
              </li>
            </ul>
          </el-form-item>
          <!-- <el-form-item label="默认首页地图视角:">
          <div class="map-small" id="map-small"></div>
          <el-radio-group v-model="radio" @change="changeMapStyle">
            <el-radio :label="0">标准</el-radio>
            <el-radio :label="1">月光银</el-radio>
            <el-radio :label="2">幻影黑</el-radio>
            <el-radio :label="3">靛青蓝</el-radio>
            <el-radio :label="4">雅士灰</el-radio>
            <el-radio :label="5">马卡龙</el-radio>
            <el-radio :label="6">极夜蓝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认主题:">
          <ul class="theme-color">
            <li class="one" @click="selectTheme(1)">
              <i class="el-icon-check" style="font-size: 24px; color: #409eff" v-if="queryForm.theme === 1"></i>
            </li>
            <li class="two" @click="selectTheme(2)">
              <i class="el-icon-check" style="font-size: 24px; color: #409eff" v-if="queryForm.theme === 2"></i>
            </li>
            <li class="three" @click="selectTheme(3)">
              <i class="el-icon-check" style="font-size: 24px; color: #409eff" v-if="queryForm.theme === 3"></i>
            </li>
            <li class="four" @click="selectTheme(4)">
              <i class="el-icon-check" style="font-size: 24px; color: #409eff" v-if="queryForm.theme === 4"></i>
            </li>
            <li class="five" @click="selectTheme(5)">
              <i class="el-icon-check" style="font-size: 24px; color: #409eff" v-if="queryForm.theme === 5"></i>
            </li>
          </ul>
        </el-form-item> -->
        </el-form>
        <!-- 登录页页面预览弹框 -->
        <el-dialog :visible.sync="previewDialog">
          <el-image fit="contain"
            :src="require(`@/assets/images/group/login${this.queryForm.login_page}.png`)"></el-image>
        </el-dialog>
      </div>
    </le-card>

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import request from '@/api/systemapi'
import { lazyAMapApiLoaderInstance } from 'vue-amap'
export default {
  data () {
    return {
      loginpageList: [1, 2, 3, 4],
      themeList: [1, 2, 3, 4],
      // 查询表单
      queryForm: {
        _id: '',
        login_logo: '',
        login_title: '',
        group_name: '',
        logo_url: '',
        theme: 1,
        platform_name: '',
        lng: '',
        lat: '',
        zoom: 9,
        map_style: '',
        login_page: 1
      },
      mapdialog: false,
      mapSmall: {},
      mapBig: {},
      radio: 4,
      dialogImageUrl: '',
      disabled: false,
      previewDialog: false // 预览登录页弹出框
    }
  },
  created () {
    // this.initrequest();
  },
  mounted () {
    this.initrequest()
  },
  computed: {},
  methods: {
    ...mapMutations(['setProjectInfo', 'setplatformInfo']),
    ...mapMutations('menuList', ['setTheme']),
    async initrequest () {
      const res = await request.queryPlatformConfig()
      if (res && res.Code === 200) {
        if (res.Data) {
          const data = res.Data
          this.queryForm = {
            _id: data._id,
            login_logo: data.login_logo
              ? data.login_logo
              : require('@/assets/img/login-log.png'),
            login_title: data.login_title,
            group_name: data.group_name,
            logo_url: data.logo_url,
            theme: data.theme,
            platform_name: data.platform_name,
            lng: data.lng,
            lat: data.lat,
            zoom: data.zoom < 3 ? 3 : data.zoom,
            map_style: data.map_style,
            login_page: data.login_page
          }
        } else {
          this.queryForm = {
            login_logo: require('@/assets/img/login-log.png'),
            login_title: '城市停车平台后台管理系统',
            group_name: '',
            // logo_url: require('../../assets/img/home_logo.png'),
            logo_url: '',
            theme: 1,
            platform_name: '城市停车平台后台管理系统',
            lng: '',
            lat: '',
            zoom: 3,
            map_style: '',
            login_page: 1
          }
        }
      }
      // setTimeout(() => {
      //   this.mapSmalldisplay();
      // }, 200);
    },
    async saveConfig () {
      const res = await request.updatPlatformConfig(this.queryForm)
      if (res && res.Code === 200) {
        this.$msg.success('保存成功')
        // this.$store.commit("setProjectInfo", this.queryForm);
      }
      this.initrequest()
      this.$store.dispatch('fngetLoginPageInfoByDomainAddr')
      this.setTheme(this.queryForm.theme)
    },
    mapSmalldisplay () {
      var that = this
      lazyAMapApiLoaderInstance.load().then(() => {
        this.mapSmall = new AMap.Map('map-small', {
          zoomEnable: true,
          resizeEnable: false,
          features: ['bg', 'road', 'building', 'point']
        })
        this.mapSmall.clearMap()
        if (this.queryForm.lng && this.queryForm.lat) {
          this.mapSmall.setCenter([this.queryForm.lng, this.queryForm.lat])
          this.mapSmall.setZoom(this.queryForm.zoom)
        }
        AMap.event.addListener(that.mapSmall, 'zoomend', function () {
          var zoom = that.mapSmall.getZoom()
          var lnglat = that.mapSmall.getCenter()
          // console.log('zoomend----zoom---', zoom);
          // console.log('zoomend----lnglat----', lnglat);
          that.queryForm.zoom = zoom
          that.queryForm.lng = lnglat.lng
          that.queryForm.lat = lnglat.lat
        })
        AMap.event.addListener(that.mapSmall, 'mapmove', function () {
          var zoom = that.mapSmall.getZoom()
          var lnglat = that.mapSmall.getCenter()
          // console.log('mapmove----zoom---', zoom);
          // console.log('mapmove----lnglat----', lnglat);
          that.queryForm.zoom = zoom
          that.queryForm.lng = lnglat.lng
          that.queryForm.lat = lnglat.lat
        })
        this.changeMapStyle(
          (that.radio = that.getMapStyleNum(that.queryForm.map_style))
        )
      })
    },
    selectTheme (theme) {
      this.queryForm.theme = theme
    },
    changeMapStyle (label) {
      var styleName = 'amap://styles/'
      var map_style
      switch (label) {
        case 0:
          map_style = 'normal'
          break
        case 1:
          map_style = 'light'
          break
        case 2:
          map_style = 'dark'
          break
        case 3:
          map_style = 'blue'
          break
        case 4:
          map_style = 'grey'
          break
        case 5:
          map_style = 'macaron'
          break
        case 6:
          map_style = 'darkblue'
          break
        default:
          map_style = 'grey'
          break
      }
      this.queryForm.map_style = map_style
      this.mapSmall.setMapStyle(styleName + map_style)
    },
    upload (item) {
      // console.log("==--item=-==", item.file.uid);
      // console.log("==--item=-==", item.file.name);
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      // console.log("-----...-----" + imageName);
      // 预览图片
      const src = window.URL.createObjectURL(item.file)
      // console.log("==-=-=src-=-=", src);
      // 将图片文件转化成base64格式图片
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log("==-=-base64image=-=-", base64image);
        const res = await request.updataIcon({
          name: item.file.name,
          strbase64: [base64image]
        })
        var url = ''
        if (res.data && res.data.length > 0) {
          url = res.data[0].FilePath
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        this.queryForm.logo_url = url
      }
      reader.readAsDataURL(item.file)
    },
    LoginUpload (item) {
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      // 预览图片
      const src = window.URL.createObjectURL(item.file)
      // 将图片文件转化成base64格式图片
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        const res = await request.updataIcon({
          name: item.file.name,
          strbase64: [base64image]
        })
        var url = ''
        if (res.data && res.data.length > 0) {
          url = res.data[0].FilePath
        } else {
          this.$msg.warning('你的图片格式有误请重新选择!')
        }
        this.queryForm.login_logo = url
      }
      reader.readAsDataURL(item.file)
    },
    // 查看图片
    viewImg () {
      this.viewImgDialog = true
      // 给遮罩层绑定点击关闭事件
      this.$nextTick(() => {
        const mask = document.getElementsByClassName('el-image-viewer__mask')
        mask[0].onclick = () => {
          this.closeViewer()
        }
      })
    },
    // 关闭查看器
    closeViewer () {
      this.viewImgDialog = false
    },
    // 删除上传logo
    deleteLogo () {
      this.queryForm.logo_url = ''
    },
    // 打开登录页预览弹框
    previewLoginPage () {
      console.log(this.queryForm.login_url)
      this.previewDialog = true
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  height: 95%;
  text-align: left;
  margin-top: 20px;
}

.addForm {
  /deep/ .content_box {
    .el-form-item__content {
      display: flex;

      .imagelogo {
        width: 200px;
        max-height: 100px;
        object-fit: contain;
        margin-right: 10px;
        background-color: #eee;
      }
    }
  }
}

.theme_box {
  display: flex;
  align-items: end;
  position: relative;
  .hover_box:hover{
    .preview_box{
      display: flex;
    align-items: center;
    justify-content: center;
    }
  }
  .preview_box{
    display: none;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    // background-color: RGBA(230, 230, 230, .3);
    background-color: rgba(0,0,0,.3);
    z-index: 2000;
  }
  .img_box {
    width: 198px;
    height: 108px;
    margin-right: 10px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    position: relative;

    .el-image {
      width: 100%;
      height: 100%;
    }

    .iconfont {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 24px;
      color: #01a6fb;
    }
  }

  .is_check {
    border: 1px solid #01a6fb;
  }

  .is_disabled {
    opacity: 0.7;
  }
}

.logo_upload_box {
  /deep/ .el-form-item__content {
    display: flex;
    // align-items: center;
    flex-direction: column;
    .deleteLogo {
      margin-right: 10px;
    }

    .el-image {
      height: 50px;
      margin-right: 10px;
      // background-color: #eee;

      img {
        width: auto;
      }
    }
  }
}

// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
// .theme-color {
//   display: flex;
//   justify-content: start;
//   align-items: center;
//   margin-block-start: 0em;
//   padding-inline-start: 0;
//   li {
//     width: 40px;
//     height: 40px;
//     margin-right: 5px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .one {
//     // background-color: #303133;
//     background-color: #000;
//   }
//   .two {
//     background-color: #000b3e;
//     // background-color: #409eff;
//   }
//   .three {
//     background-color: #273987;
//     // background-color: #32cd99;
//   }
//   .four {
//     background-color: #f7f7f7;
//     border-color: #999;
//     border-width: 1px;
//     border-style: solid;
//     // background-color: #909399;
//   }
//   .five {
//     width: 38px;
//     height: 38px;
//     background-color: #ffffff;
//     border-color: #999;
//     border-width: 1px;
//     border-style: solid;
//   }
// }
.handelButton {
  display: flex;
  align-items: center;
}

.logoinfo {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #CCCCCC;

  &::before {
    margin-right: 4px;
  }
}
/deep/.el-dialog{
  .el-dialog__header{
    padding: unset;
    .el-dialog__headerbtn{
      z-index: 5;
    }
  }
  .el-dialog__body{
    padding: unset;
    .el-image{
      margin-bottom: -8px;
    }
  }
}
// .map-small {
//   width: 700px;
//   height: 300px;
// }
</style>
